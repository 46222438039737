import React, {
  useState,
  useEffect,
  useRef,
  useContext,
  useMemo,
  forwardRef,
} from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import * as d3 from "d3";
import Request from "common/Request";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import { useDimensions } from "common/hooks";
import {
  expressTarget,
  retrieveRange,
  doSystemDateRetrieval,
  parseSystemDate,
  calcRangeExtent,
  isInsideRange,
} from "common/api";
import RangeSliderInput from "common/RangeSliderInput";
import NVLineChart from "common/graphs/NVLineChart";
import formats from "common/graphs/formats";
import ActionsContext from "common/ActionsContext";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import { ConnectedLineChart } from "common/graphs/ConnectedLineChart";
import ScatterPlot from "common/graphs/ScatterPlot/ScatterPlot";
import { TileContainer } from "common/graphs/FacetGraph";
import FacetGraph from "common/graphs/ConnectedFacetGraph";
import {
  parseSubscriberFlows,
  calcFlowsCreationPerMinute,
  parseSubscriberMaxSpeeds,
  parseSubscriberLatencies,
  parseSubscriberRetransmissions,
  parseTrafficCongestion,
  parseLatencyReduction,
  parseRetransmissionsReduction,
} from "./api";
import { toObjectLiteral } from "common/utils";

const EVENT_HIGHLIGHT = "nv-line-chart-highlight";

const ControlsContainer = styled.div`
  width: 100%;
  display: flex;
  padding: 0 0 20px 0;
  justify-content: space-between;
  align-items: center;
`;

const SubcriberDetailsContextControlsLayout = styled.div`
  display: flex;
  flex-layout: row;
  width: 100%;
  *:first-child {
    flex: 10% 1 0;
  }
  *:last-child {
    flex: 100% 1 1;
  }
  & .input-group {
    max-width: 20rem;
  }
`;

const ContextMenuDiv = styled.div`
  display: flex;
  align-items: center;

  i {
    color: #999;
    font-size: 20px;

    &:hover {
      color: #000;
    }
  }
`;

const H4Centered = styled.h4`
  font-size: 18px;
  margin-top: 7px;
  margin-bottom: 7px;
`;

const doesNothing = () => {};
const MARGIN_PLOT = { top: 0, right: 40, bottom: 50, left: 60 };
const PADDING_CHART_CONTAINER = 30;

const noIPv6Mask = { ipv6Mask: false };

export const doStatsRetrieval = ({
  target = null,
  facet = "volume",
  hours = 24,
  interval = 10,
}) =>
  expressTarget(target, noIPv6Mask).then((expressedTarget) =>
    ifCl.run(
      `show statistics subscribers ${facet} ${expressedTarget} hours ${hours} interval ${interval}`
    )
  );

const systemTimeRetrieval = () => doSystemDateRetrieval().then(parseSystemDate);

const _hoursScopeChoices = [
  { value: 24, label: "1 Day" },
  { value: 24 * 7, label: "1 Week" },
  { value: 24 * 31, label: "1 Month" },
  { value: 24 * 31 * 3, label: "3 Months" },
];

const SubcriberDetailsContextControls = ({
  range,
  hours,
  onChange = doesNothing,
  children,
}) => {
  const handleSelectChange = ({ target }) => {
    onChange({ hours: parseInt(target.value) });
  };

  return (
    <>
      <TileContainer className="full-width can-compress no-print historical-metrics-controls">
        <SelectInputWithIcon
          title="Date Range"
          name="Date Range"
          icon="date_range"
          selected={hours || 24}
          onChange={handleSelectChange}
          options={_hoursScopeChoices}
        />
      </TileContainer>
      {children}
      <TileContainer className="full-width can-compress no-print">
        <RangeSliderInput {...range} onChange={onChange} />
      </TileContainer>
    </>
  );
};

const parseAndCalcFlowsCreationPerMinute = (response) =>
  calcFlowsCreationPerMinute(parseSubscriberFlows(response));

const scatterItemsXSpeed = (yItems, speedItems, yLabel) =>
  yItems && yItems.length > 0 ?
  speedItems.reduce((acc, item, index) => {
    const { downMbPerSec, time } = item;
    if (yItems[index][yLabel] !== null && downMbPerSec !== null)
      acc.push({ y: yItems[index][yLabel], x: downMbPerSec, time });
    return acc;
  }, [])
  : [];

const formatDate = d3.timeFormat("%m/%d %H:%M");

function getTimeValuesMaps(itemsCharts) {
  return Object.keys(itemsCharts).reduce((acc, itemsChart) => {
    const mapKey = `${itemsChart}Map`;
    const items = itemsCharts[itemsChart];
    if (items && items.length === 0) {
      acc[mapKey] = {};
    }
    const mapValues = items.reduce((acc, value) => {
      const { time } = value;
      const timeKey = formatDate(time);
      acc[timeKey] = value;
      return acc;
    }, {});
    acc[mapKey] = mapValues;
    return acc;
  }, {});
}

const ScatterPlotLatencySpeed = forwardRef(
  ({ items, interactionData, onInteractionData }, ref) => {
    return (
      <TileContainer className="chart-container" ref={ref}>
        <h4 className="chart-title lowercase">Latency vs Average Speed</h4>
        <div className="graph">
          <ScatterPlot
            items={items}
            contentY={{
              yAxisLabel: "Access latency (ms)",
              tooltipLabel: "Latency",
              tooltipUnit: "ms",
            }}
            contentX={{
              xAxisLabel: "Average downlink speed (Mbps)",
              tooltipLabel: "Average Speed",
              tooltipUnit: "Mbps",
            }}
            interactionData={interactionData}
            onInteractionData={onInteractionData}
          />
        </div>
      </TileContainer>
    );
  }
);

ScatterPlotLatencySpeed.displayName = 'ScatterPlotLatencySpeed'; 

const SubscriberAnalyticsResult = ({
  target,
  range: initialRange,
  itemsSpeed,
  itemsMaxSpeed,
  itemsLatency,
  itemsRetransmission,
  itemsFlows,
  itemsCongestionLatency,
  itemsCongestionRetransmission,
  itemsCongestionTraffic,
  interval = 10,
  hours = 24,
  onHoursChange = doesNothing,
}) => {
  const scatterPlotRef = useRef();
  const dimensions = useDimensions(scatterPlotRef);
  const widthChart =
    dimensions.width -
    MARGIN_PLOT.left -
    MARGIN_PLOT.right -
    PADDING_CHART_CONTAINER;
  const [range, setRange] = useState(initialRange);
  const applyRange = (newRange) => setRange(newRange);
  const [interactionData, setInteractionData] = useState(null);
  const actions = useContext(ActionsContext);
  const applyChange = ({ hours, ...range }) => {
    if (hours !== undefined) {
      onHoursChange(hours);
    } else {
      applyRange(range);
    }
  };

  const {
    itemsSpeedMap,
    itemsLatencyMap,
    itemsFlowsMap,
    itemsRetransmissionMap,
    itemsMaxSpeedMap,
    itemsCongestionLatencyMap,
    itemsCongestionRetransmissionMap,
    itemsCongestionTrafficMap,
  } = useMemo(() => {
    return getTimeValuesMaps({
      itemsSpeed,
      itemsLatency,
      itemsFlows,
      itemsRetransmission,
      itemsMaxSpeed,
      itemsCongestionLatency,
      itemsCongestionRetransmission,
      itemsCongestionTraffic,
    });
  }, [
    itemsSpeed,
    itemsLatency,
    itemsFlows,
    itemsRetransmission,
    itemsMaxSpeed,
    itemsCongestionLatency,
    itemsCongestionRetransmission,
    itemsCongestionTraffic,
  ]);

  const xScale = useMemo(() => {
    return d3
      .scaleTime()
      .range([0, widthChart])
      .domain([itemsSpeed[0].time, itemsSpeed[itemsSpeed.length - 1].time]);
  }, [itemsSpeed, widthChart]);

  return (
    <ColumnsGrid
      gap="1rem"
      columns={2}
      minWidth="9cm"
      className="historical-metrics-content"
    >
      <SubcriberDetailsContextControls
        target={target}
        range={initialRange}
        hours={hours}
        onChange={applyChange}
      >
        <FacetGraph
          title="Average Speed Over Time"
          items={itemsSpeed.filter(isInsideRange(range))}
          facet="speed"
          yAxisUnits="Mbps"
          fields={[
            { name: "downMbPerSec", label: "Down-Mbps" },
            { name: "upMbPerSec", label: "Up-Mbps" },
          ]}
          onInteractionData={setInteractionData}
          interactionData={interactionData}
          valuesMap={itemsSpeedMap}
          xScale={xScale}
          widthChart={widthChart}
          hours={hours}
        />
        <FacetGraph
          title="Maximum Speed Over Time"
          items={itemsMaxSpeed.filter(isInsideRange(range))}
          facet="max-speed"
          yAxisUnits="Mbps"
          fields={[
            { name: "downMbPerSec", label: "Down-Mbps" },
            { name: "upMbPerSec", label: "Up-Mbps" },
          ]}
          onInteractionData={setInteractionData}
          interactionData={interactionData}
          valuesMap={itemsMaxSpeedMap}
          xScale={xScale}
          widthChart={widthChart}
          hours={hours}
        />
        <FacetGraph
          title="Latency Over Time"
          items={itemsLatency.filter(isInsideRange(range))}
          facet="latency"
          yAxisUnits="milliseconds"
          fields={[
            { name: "subsAccessRttMs", label: "Subs-Access-Rtt-Ms" },
            { name: "networkAccessRttMs", label: "Network-Access-Rtt-Ms" },
          ]}
          onInteractionData={setInteractionData}
          interactionData={interactionData}
          valuesMap={itemsLatencyMap}
          xScale={xScale}
          widthChart={widthChart}
          hours={hours}
        />
        <FacetGraph
          title="Packet retransmissions Over Time"
          items={itemsRetransmission.filter(isInsideRange(range))}
          facet="retransmission"
          yAxisUnits="% retransmisions"
          fields={[
            { name: "subsAccessRtx", label: "Subs-Access-Rtx-%" },
            { name: "networkAccessRtx", label: "Network-Access-Rtx-%" },
          ]}
          onInteractionData={setInteractionData}
          interactionData={interactionData}
          valuesMap={itemsRetransmissionMap}
          xScale={xScale}
          widthChart={widthChart}
          hours={hours}
        />
        <FacetGraph
          title="Average reduction in latency with ACM Over Time"
          items={itemsCongestionLatency.filter(isInsideRange(range))}
          facet="congestion-latency"
          yAxisUnits="milliseconds"
          fields={[{ name: "reduction", label: "Reduction-ms" }]}
          onInteractionData={setInteractionData}
          interactionData={interactionData}
          valuesMap={itemsCongestionLatencyMap}
          xScale={xScale}
          widthChart={widthChart}
          hours={hours}
        />
        <FacetGraph
          title="Average reduction in retransmissions with ACM Over Time"
          items={itemsCongestionRetransmission.filter(isInsideRange(range))}
          facet="congestion-retransmission"
          yAxisUnits="% retransmissions"
          fields={[{ name: "reduction", label: "Reduction-%" }]}
          onInteractionData={setInteractionData}
          interactionData={interactionData}
          valuesMap={itemsCongestionRetransmissionMap}
          xScale={xScale}
          widthChart={widthChart}
          hours={hours}
        />
        <FacetGraph
          title="Average traffic at high speed and under congestion Over Time"
          items={itemsCongestionTraffic.filter(isInsideRange(range))}
          facet="congestion-total"
          yAxisUnits="% of total traffic"
          fields={[
            { name: "maxSpeed", label: "Max-speed-traffic-%" },
            { name: "congestion", label: "Congested-traffic-%" },
            { name: "acm", label: "ACM-%" },
          ]}
          onInteractionData={setInteractionData}
          interactionData={interactionData}
          valuesMap={itemsCongestionTrafficMap}
          xScale={xScale}
          widthChart={widthChart}
          hours={hours}
        />
        <FacetGraph
          title="Flows Over Time"
          items={itemsFlows.filter(isInsideRange(range))}
          facet="flows"
          yAxisUnits="Flows"
          fields={[
            { name: "flCreatedPerMinute", label: "Fl-Created-per-minute" },
            { name: "flActive", label: "Fl-Active" },
          ]}
          onInteractionData={setInteractionData}
          interactionData={interactionData}
          valuesMap={itemsFlowsMap}
          xScale={xScale}
          widthChart={widthChart}
          hours={hours}
        />
        <ScatterPlotLatencySpeed
          items={scatterItemsXSpeed(
            itemsLatency,
            itemsSpeed,
            "subsAccessRttMs"
          ).filter(isInsideRange(range))}
          interactionData={interactionData}
          onInteractionData={setInteractionData}
          ref={scatterPlotRef}
        />
        <TileContainer className="chart-container">
          <h4 className="chart-title lowercase">
            Packet Retransmissions vs Average Speed
          </h4>
          <div className="graph">
            <ScatterPlot
              items={scatterItemsXSpeed(
                itemsRetransmission,
                itemsSpeed,
                "subsAccessRtx"
              ).filter(isInsideRange(range))}
              contentY={{
                yAxisLabel: "Retransmissions (%)",
                tooltipLabel: "Retransmissions",
                tooltipUnit: "%",
              }}
              contentX={{
                xAxisLabel: "Average downlink speed (Mbps)",
                tooltipLabel: "Average Speed",
                tooltipUnit: "Mbps",
              }}
              interactionData={interactionData}
              onInteractionData={setInteractionData}
            />
          </div>
        </TileContainer>
        <TileContainer className="chart-container">
          <h4 className="chart-title lowercase">Congestion vs Average Speed</h4>
          <div className="graph">
            <ScatterPlot
              items={scatterItemsXSpeed(
                itemsCongestionLatency,
                itemsSpeed,
                "reduction"
              ).filter(isInsideRange(range))}
              contentY={{
                yAxisLabel: "Congested traffic (%)",
                tooltipLabel: "Congestion",
                tooltipUnit: "%",
              }}
              contentX={{
                xAxisLabel: "Average downlink speed (Mbps)",
                tooltipLabel: "Average Speed",
                tooltipUnit: "Mbps",
              }}
              interactionData={interactionData}
              onInteractionData={setInteractionData}
            />
          </div>
        </TileContainer>
      </SubcriberDetailsContextControls>
    </ColumnsGrid>
  );
};

const promisesInfo = [
  { facet: "speed", parse: parseSubscriberMaxSpeeds },
  { facet: "max-speed", parse: parseSubscriberMaxSpeeds },
  { facet: "latency", parse: parseSubscriberLatencies },
  { facet: "retransmission", parse: parseSubscriberRetransmissions },
  { facet: "flows", parse: parseAndCalcFlowsCreationPerMinute },
  { facet: "congestion", parse: parseLatencyReduction },
  { facet: "congestion", parse: parseRetransmissionsReduction },
  { facet: "congestion", parse: parseTrafficCongestion },
];

const promises = ({ target, hours, interval }) => {
  return Promise.all(
    promisesInfo.map(({ facet, parse }) => {
      return doStatsRetrieval({
        facet,
        target,
        hours,
        interval,
      })
        .then(parse)
        .catch((error) => console.error(error) || []);
    })
  ).then((resp) => resp);
};

const timePeriodMap = {
  24: "oneDay",
  168: "oneWeek",
  744: "oneMonth",
  2232: "threeMonths",
};

const retrieval = ({ target, hours, interval }) => {
  return Promise.all([
    systemTimeRetrieval().then((systemDate) =>
      calcRangeExtent(systemDate, hours)
    ),
    promises({ target, hours, interval }),
  ]).then(
    ([
      range,
      [
        itemsSpeed,
        itemsMaxSpeed,
        itemsLatency,
        itemsRetransmission,
        itemsFlows,
        itemsCongestionLatency,
        itemsCongestionRetransmission,
        itemsCongestionTraffic,
      ],
    ]) => {
      return {
        range,
        itemsSpeed,
        itemsMaxSpeed,
        itemsLatency,
        itemsRetransmission,
        itemsFlows,
        itemsCongestionLatency,
        itemsCongestionRetransmission,
        itemsCongestionTraffic,
      };
    }
  );
};

const SubscriberAnalyticContextRequest = ({
  target,
  context,
  onHoursChange = doesNothing,
}) => {
  const [request, setRequest] = useState(null);
  let [searchParams, setSearchParams] = useSearchParams();

  const doLoad = () => {
    setRequest(context.hours ? retrieval({ target, ...context }) : null);
  };

  const targetStr = target ? (target.addr ? target.addr : target.subsId) : "";
  const contextStr = "" + context.hours + context.interval;

  useEffect(() => {
    if (targetStr) {
      doLoad();
    }
  }, [contextStr, targetStr]);

  return request === null ? null : (
    <Request during={request}>
      {(result) => {
        const { range } = result;
        return (
          <>
            <ControlsContainer className="historical-metrics-header">
              <H4Centered className="subtitle"> Historical Metrics </H4Centered>
              <ContextMenuDiv className="context-menu">
                <a
                  onClick={doLoad}
                  data-toggle="cardloading"
                  data-loading-effect="pulse"
                  title="Refresh"
                >
                  <i className="material-icons">refresh</i>
                </a>
              </ContextMenuDiv>
            </ControlsContainer>
            <SubscriberAnalyticsResult
              {...result}
              {...context}
              target={target}
              onHoursChange={(hours) => {
                onHoursChange(hours);
                setSearchParams({
                  ...toObjectLiteral(searchParams),
                  timePeriod: timePeriodMap[hours],
                });
              }}
            />
          </>
        );
      }}
    </Request>
  );
};

const _defaultContext = {
  hours: 24,
  interval: 10,
};

const intervalForHours = (hours, interval = 10) =>
  hours > 7 * 24 ? 60 : interval;

const hoursMap = {
  oneDay: 24,
  oneWeek: 24 * 7,
  oneMonth: 24 * 31,
  threeMonths: 24 * 31 * 3,
};

const SubscriberAnalyticContext = ({ target }) => {
  let [searchParams, _] = useSearchParams();

  const [context, setContext] = useState({
    ..._defaultContext,
    hours: hoursMap[searchParams.get("timePeriod")] ?? _defaultContext.hours,
  });

  const setHoursInContext = (hours) =>
    setContext({ hours, interval: intervalForHours(hours) });

  return (
    <SubscriberAnalyticContextRequest
      target={target}
      context={context}
      onHoursChange={setHoursInContext}
    />
  );
};
export default SubscriberAnalyticContext;
