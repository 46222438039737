import React, { useState, useContext } from "react";
import Modal from "common/Modal.jsx";
import ActionsContext from "common/ActionsContext";

const ConfigExternalBypassDevicesHelpModal = () => {
  const [open, setOpen] = useState(false);
  const actions = useContext(ActionsContext);
  actions.recv("open-help", function () {
    setOpen(true);
  });
  const doClose = () => setOpen(false);
  if (open !== true) {
    return null;
  }

  return (
    <Modal
      title="External Bypass Devices Configuration"
      onClose={doClose}
      content={() => (
        <>
          <p>This window shows the mode of the bypass device that sends data traffic to the BQN server
             in normal circumstances and bypasses the BQN server when an error is detected.
             More than one bypass device is possible. All links of all devices are all in the same mode.
             Possible modes are:
          </p>
          <ul>
            <li><b>Enabled</b>: this is the normal mode. The device sends traffic through the BQN and, if the BQN fails
            to answer, sends the traffic bypassing the BQN in order to preserve traffic connectivity.</li>
            <li><b>Forced bypass</b>: the device activates the bypass path regardless of the BQN state. It is used to
            take the BQN server off the traffic during maintenance tasks (e.g. an upgrade requiring server reboots).</li>
            <li><b>Disabled</b>: there is no bypass device.</li>
          </ul>
          <p>If you change the mode, you need to click on Apply to enforce the new configuration.</p>
          <p>Click on the <i className="material-icons valign-icon">refresh</i> icon to refresh status devices information.</p>
        </>
      )}
    />
  );
};

export default ConfigExternalBypassDevicesHelpModal;
