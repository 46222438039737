/* globals ifCl */
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import * as d3 from "d3";

import SelectInputWithIcon from "common/SelectInputWithIcon";
import ActionsContext from "common/ActionsContext";
import NVLineChart from "common/graphs/NVLineChart";
import NVStackedAreaChart from "common/graphs/NVStackedAreaChart";
import { useScreenSize } from "common/hooks";
import { calcRangeExtent } from "common/api";
import Loading from "common/Loading";
import ColumnsGrid from "common/layouts/ColumnsGrid";
import {
  parseSystemDate,
  getSampleSize,
} from "common/api";
import RangeSliderInput from "common/RangeSliderInput";
import {
  PERIOD_TO_HOURS,
  PERIOD_TO_LINES,
  periodScopeChoices,
} from "common/constants";

import { parseStatsInterfacesData, parseWire, getInRangeItems } from "./api";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const NoData = styled.div`
  display: flex;
  width: 100%;
  height: 500px;
  align-items: center;
  justify-content: center;
  font-family: AllianceNo2-SemiBold !important;
`;

const SelectWrapper = styled.div`
  width: 150px;
`;


const ChartContainer = styled.div`
  min-height: 500px;
  display: flex;
  flex-flow: column nowrap;
  svg.nvd3-svg .nv-axis path.domain {
    stroke-opacity: 0;
  }
  .nv-y.nv-axis .nv-axisMaxMin {
    display: none;
  }
  .nv-y.nv-axis .tick.zero line {
    stroke: transparent !important;
  }
  .nvd3 .nv-groups path.nv-line {
    stroke-width: 1.1;
  }
  .single-graph {
    height: 500px;
  }
`;

const RangeContainer = styled.div`
`;

const Title = styled.h4`
  margin: 20px 0 15px;
  font-size: 14px !important;
`;

const BelowTitle = styled.h4`
  margin: 40px 0 13px;
  font-size: 14px !important;
`;

const ChartWrapper = styled.div`
`;

const LoadingContainer = styled.div`
  min-height: 250px;
  display: flex;
  align-items: center;
`;

const _directionChoices = [
  { value: "Tx", label: "Tx" },
  { value: "Rx", label: "Rx" },
];

const wireAndSystemDateRetrieval = () =>
  Promise.all([
    ifCl.run("show pkteng wire").then(parseWire),
    ifCl.run("show system date").then(parseSystemDate),
  ]);

export function getInterfacesStatsLines(lines = 288) {
  return ifCl.run(`show statistics interface speed lines ${lines}`);
}

const getSpecDatasets = (app, data, interfaces) => {
  if (interfaces !== "All") {
    return {};
  }
  const { names, dataSets } = data;
  const namesSpec = names.filter((name) => name.includes(app)) || [];
  return namesSpec.reduce((acc, value) => {
    acc[value] = dataSets[value];
    return acc;
  }, {});
};

const SpecDataSets = ({
  sets,
  direction,
  range,
  initialRange,
  type,
  sampleSize,
  loading
}) => {
  if (loading) {
    return (
      <>
        <Title>{`${type} Ports`}</Title>
        <LoadingContainer>
          <Loading />
        </LoadingContainer>
      </>
    );
  }
  const [name0, ...restNames] = Object.keys(sets);
  const itemsName0 = sets[name0]?.items || [];
  const { items: initItems, isZeroOrNull: initZeroOrNull } = itemsName0.reduce(
    (acc, value, index) => {
      if (value[direction] !== 0 && value[direction] !== null) {
        acc.isZeroOrNull = false;
      }
      const newItem = restNames.reduce(
        (accNewItem, valueNewItem) => {
          accNewItem[valueNewItem] = sets[valueNewItem].items[index][direction];
          return accNewItem;
        },
        { time: value.time, [name0]: value[direction] }
      );
      acc.items.push(newItem);
      return acc;
    },
    { items: [], isZeroOrNull: true }
  );

  const fields = [name0, ...restNames];
  const { items, isZeroOrNull } = getInRangeItems({
    items: initItems,
    sampleSize,
    range,
    initialRange,
    fields,
    initZeroOrNull,
  }) || {};

  return (
    <>
      {type === 'Access' && <Title>{`${type} Ports`}</Title>}
      {type === 'Internet' && <BelowTitle>{`${type} Ports`}</BelowTitle>}
      <ChartWrapper>
        <NVStackedAreaChart
          xField="time"
          items={items}
          yAxisUnits={"Mbps"}
          yAxisFormat={function (d) {
            if (d == 0) {
              return d3.format(".0f")(d);
            } else if (d >= 10.0) {
              return d3.format(".0f")(d);
            } else if (d >= 1.0) {
              return d3.format(".1f")(d);
            } else {
              return d3.format(".2f")(d);
            }
          }}
          xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
          fields={fields.map((field) => {
            return { name: field, label: field };
          })}
          yAxisTopGap={0.05}
          yAxisTicksNumber={5}
          xAxisTicksNumber={8}
          yTooltipFormat={(d) => d3.format(".2f")(d) + " Mbps"}
          fixedYRange={isZeroOrNull ? [0, 1] : [0, null]}
          skipVoid={false}
        />
      </ChartWrapper>
    </>
  );
};

const InterfacesReport = () => {
  const { width: windowWidth } = useScreenSize();
  const [loading, setLoading] = useState(false);
  const actions = useContext(ActionsContext);
  const [period, setPeriod] = useState("Day");
  const [interfaces, setInterfaces] = useState("All");
  const [interfacesChoices, setInterfacesChoices] = useState([
    { value: "All", label: "All" },
  ]);
  const [direction, setDirection] = useState("Tx");
  const [initialRange, setInitialRange] = useState();
  const [range, setRange] = useState();
  const [data, setData] = useState({ names: [], dataSets: {} });
  const POINTS_PER_TILE_CHART = windowWidth / 2;

  const doLoad = () => {
    setLoading(true);
    setRange(undefined);
    let extPortName;
    let initRange;
    wireAndSystemDateRetrieval()
      .then(([wire, SysDate]) => {
        extPortName = wire;
        initRange = calcRangeExtent(SysDate, PERIOD_TO_HOURS[period]);
        setInitialRange(initRange);
        return getInterfacesStatsLines(PERIOD_TO_LINES[period]);
      })
      .then((response) => {
        return parseStatsInterfacesData(
          response,
          period,
          extPortName,
          initRange
        );
      })
      .then((response) => {
        const { options, names, dataSets } = response;
        setInterfacesChoices(
          options.map((option) => {
            return { value: option, label: option };
          })
        );
        setData({ names, dataSets });
        setLoading(false);
      })
      .catch((error) => {
        console.warn(
          `Statistics interface speed lines retrieval failed: ${
            !error.message ? "unknown reason" : error.message
          }`
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    doLoad();
    return actions.recv("do-load", doLoad);
  }, [period]);

  const { items: initItems, isZeroOrNull: initZeroOrNull } =
    data.dataSets[interfaces] || {};
  const dataSetsInet = getSpecDatasets("-inet", data, interfaces);
  const dataSetsAccess = getSpecDatasets("-access", data, interfaces);
  const selectedRange = range || initialRange;
  const sampleSize = selectedRange
    ? getSampleSize(selectedRange, POINTS_PER_TILE_CHART, period)
    : 1;
  const { items, isZeroOrNull } = getInRangeItems({
    items: initItems,
    sampleSize,
    range,
    initialRange,
    fields: ["Tx", "Rx"],
    initZeroOrNull,
  }) || {};

  return (
    <Wrapper>
      <ColumnsGrid
        columns={6}
        minWidth="15rem"
        rowGap="0"
      >
        <SelectInputWithIcon
          title="Network Interface"
          name="Network Interface"
          icon="device_hub"
          selected={interfaces}
          onChange={({ target }) => setInterfaces(target.value)}
          options={interfacesChoices}
        />
        <SelectInputWithIcon
          title="Date Range"
          name="Date Range"
          icon="date_range"
          selected={period}
          onChange={({ target }) => setPeriod(target.value)}
          options={periodScopeChoices}
        />
        {interfaces === "All" ? (
          <SelectWrapper>
            <SelectInputWithIcon
              title="Direction"
              name="Direction"
              icon="import_export"
              selected={direction}
              onChange={({ target }) => setDirection(target.value)}
              options={_directionChoices}
            />
          </SelectWrapper>
        ) : null}
      </ColumnsGrid>
      <ChartContainer>
        {data.names.length === 0 ? <NoData>No Data Available</NoData> : null}
        {Object.keys(dataSetsAccess).length > 0 ? (
          <SpecDataSets
            sets={dataSetsAccess}
            direction={direction}
            range={range}
            initialRange={initialRange}
            type="Access"
            sampleSize={sampleSize}
            loading={loading}
          />
        ) : null}
        {Object.keys(dataSetsInet).length > 0 ? (
          <SpecDataSets
            sets={dataSetsInet}
            direction={direction}
            range={range}
            initialRange={initialRange}
            type="Internet"
            sampleSize={sampleSize}
            loading={loading}
          />
        ) : null}
        {interfaces !== "All" ? (
          <NVLineChart
            className="single-graph"
            xField="time"
            items={items}
            yAxisUnits={"Mbps"}
            yAxisFormat={function (d) {
              if (d == 0) {
                return d3.format(".0f")(d);
              } else if (d >= 10.0) {
                return d3.format(".0f")(d);
              } else if (d >= 1.0) {
                return d3.format(".1f")(d);
              } else {
                return d3.format(".2f")(d);
              }
            }}
            xAxisFormat={d3.timeFormat("%m/%d %H:%M")}
            fields={["Tx", "Rx"].map((field) => {
              return { name: field, label: field };
            })}
            yAxisTopGap={0.05}
            yAxisTicksNumber={5}
            xAxisTicksNumber={8}
            yTooltipFormat={(d) => d3.format(".2f")(d) + " Mbps"}
            fixedYRange={isZeroOrNull ? [0, 1] : [0, null]}
          />
        ) : null}
      </ChartContainer>
      <RangeContainer>
        {initialRange && (
          <RangeSliderInput
            {...initialRange}
            onChange={(value) => setRange(value)}
            className="margin-t-20"
          />
        )}
      </RangeContainer>
    </Wrapper>
  );
};

export default InterfacesReport;
