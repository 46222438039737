import React, { useEffect, useReducer } from "react";
import CreateVLAN from "./Create";
import ListVLANs from "./List";
import SwitchInput from "common/SwitchInput";

const doesNothing = () => {};

const higherId = (current, { __id }) => (current > __id ? current : __id);

const nextId = (items) => items.reduce(higherId, 0) + 1;
const anyTagCollisionWith = (__id, tag) => (existing) =>
  existing.tag === tag && existing.__id !== __id;

const doVerification = (item, list) => {
  const { tag, __id } = item;
  if (tag === '' || isNaN(tag) || tag < 0 || tag > 4094) {
    throw "Tag must be a number between 0 and 4094";
  }
  if (list.find(anyTagCollisionWith(__id, tag)) !== undefined) {
    throw "tag already exists.";
  }
};
function vlansReducer({ list, untagged }, { type, ...action }) {
  switch (type) {
    case "added": {
      return {
        changed: true,
        untagged,
        list: [
          ...list,
          {
            ...action.vlan,
            stored: false,
            __id: nextId(list),
          },
        ],
      };
    }
    case "deleted": {
      return {
        changed: true,
        untagged,
        list: list.flatMap(({ __id, stored, ...vlan }) =>
          __id !== action.id
            ? [{ __id, stored, ...vlan }]
            : stored === true
            ? [{ __id, stored, ...vlan, deleted: true }]
            : []
        ),
      };
    }
    case "untagged": {
      return {
        changed: true,
        untagged: action.enable,
        list,
      };
    }
    default: {
      throw Error("Unknown action: " + type);
    }
  }
}

const VLANsSettings = ({
  defaults = { list: [], untagged: true },
  onChange = doesNothing,
}) => {
  const [vlans, dispatch] = useReducer(vlansReducer, {
    changed: false,
    untagged: defaults.untagged,
    list: [...defaults.list],
  });

  const handleDeleteId = (id) => {
    dispatch({
      type: "deleted",
      id,
    });
  };
  const createVLANs = (vlan) => {
    doVerification(vlan, vlans.list);
    dispatch({
      type: "added",
      vlan,
    });
  };
  const validateNewItem = (vlan) => {
    doVerification(vlan, vlans.list);
  };
  const onEnabledChanged = (enable) => {
    dispatch({ type: "untagged", enable });
  };
  useEffect(() => {
    vlans.changed === true && onChange(vlans);
  }, [vlans]);

  return (
    <>
      <h2 className="card-inside-title">
        Bypass Packets with Specific VLAN Tags
      </h2>
      <SwitchInput
        defaultChecked={defaults.untagged}
        className={"sw-input"}
        label={(checked) =>
          `Bypass packets without VLAN tags: ${
            checked ? "enabled" : "disabled"
          }`
        }
        onChange={(event) => onEnabledChanged(event.target.checked === true)}
      />
      <CreateVLAN doSave={createVLANs} doValidate={validateNewItem} />
      <ListVLANs items={vlans.list} onDelete={handleDeleteId} />
    </>
  );
};

export default VLANsSettings;
