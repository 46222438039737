/* globals showModalConfirm */
import React, { useState, useEffect, useContext } from "react";
import {
  parseParameters,
  parseBypassParameters,
  composeApplyCommand,
} from "./api";
import { getWarnings } from "./warnings";
import ActionsContext from "common/ActionsContext";
import SettingsView from "common/SettingsView";
import ArrangeInColumns from "common/layouts/ArrangeInColumns";
import BypassSettings from "./BypassSettings";
import TCPAndShapingSettings from "./TCPAndShapingSettings";

const doesNothing = () => {};

const CANT_PROCEED_ERROR =
  "Cannot proceed while there are pending config changes." +
  " Try again later.";

const clear = () =>
  ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

const getParameters = () =>
  ifCl.run("show pkteng parameters").then(parseParameters);

const getBypassSettings = () =>
  ifCl
    .run("show pkteng bypass")
    .then(parseBypassParameters)
    .then(({ vlans, ipRanges, ...settings }) => ({
      ...settings,
      vlans: enumerate(1, { stored: true })(vlans),
      ipRanges: enumerate(1, { stored: true })(ipRanges),
    }));

const enumerate =
  (start = 1, extraParams) =>
  (list) =>
    list.map((item, index) => ({
      ...item,
      __id: start + index,
      ...extraParams,
    }));

const arrangeIntoSettings = ([settings = {}, bypass = {}]) => ({
  ...settings,
  bypass,
});

const getOptimizationSettings = () =>
  Promise.all([getParameters(), getBypassSettings()])
    .then(arrangeIntoSettings)
    .catch(console.error);

const OptimizationContextMenu = ({ doLoad = doesNothing }) => {
  const [optionsEnabled, setOptionsEnabled] = useState(true);
  const actions = useContext(ActionsContext);
  useEffect(
    () =>
      actions.recv("state-changed", (state) =>
        setOptionsEnabled(state.enabled === true)
      ),
    []
  );

  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          onClick={doLoad}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
      {optionsEnabled === true ? (
        <li className="dropdown hidden-to-operators">
          <a
            className="dropdown-toggle"
            data-toggle="dropdown"
            role="button"
            aria-haspopup="true"
            aria-expanded="false"
            title="Options"
          >
            <i className="material-icons">more_vert</i>
          </a>
          <ul className="dropdown-menu pull-right">
            <li>
              <a onClick={() => actions.send("new-client")}>Add Client...</a>
            </li>
          </ul>
        </li>
      ) : null}
    </ul>
  );
};

class UserDeclined extends Error {}

class RequiresConfirmation extends Error {
  constructor(reason) {
    super();
    this.reason = reason;
  }
}

const confirmWarning = (message, content) => {
  const done = new Promise((resolve) =>
    $("#modalConfirm").on("hidden.bs.modal", resolve)
  );
  return new Promise((resolve, reject) =>
    showModalConfirm(
      message,
      content,
      "warning",
      () => done.then(() => resolve(true)),
      () => done.then(() => reject(new UserDeclined(content))),
      "OK",
      "CANCEL"
    )
  );
};

const mayRequireConfirmation = (warning) => {
  if (warning instanceof RequiresConfirmation) {
    return confirmWarning("Do you want to proceed?", warning.reason);
  }
  throw warning;
};

const checkForWarnings = (previous, settings) => {
  const warnings = getWarnings(previous, settings);
  if (warnings.length > 0) {
    const reason = `
      <ul>
        ${warnings.map((warning) => "<li>" + warning + "</li>").join("")}
      <ul>
    `;
    throw new RequiresConfirmation(reason);
  }
  return true;
};

const doChecksOnBeforeProceed = (originalSettings, settings) => (commands) =>
  Promise.resolve(commands)
    .then(() => checkForWarnings(originalSettings, settings))
    .catch(mayRequireConfirmation)
    .then(() => commands);

const OptimizationConfig = () => {
  const load = () => getOptimizationSettings();

  const checkNothingPending = () =>
    ifCl.run("show config diff").then((response) => {
      if (response.length > 0) {
        throw new Error(CANT_PROCEED_ERROR);
      }
    });

  const clearConfigAttempt = () =>
    ifCl.run("configure\nclear config changes\n", /*is batch*/ true);

  const notifyAndRevert = (error) => {
    if (error instanceof UserDeclined) {
      return { error: null };
    }
    error = error.message === undefined ? new Error(error) : error;
    clearConfigAttempt();
    throw error;
  };

  const submitCommand = (command) =>
    ifCl.run(command + "\n", /*is batch*/ true);

  const apply = (settings, originalSettings = {}) =>
    checkNothingPending()
      .then(() => composeApplyCommand(originalSettings, settings))
      .then(doChecksOnBeforeProceed(originalSettings, settings))
      .then(submitCommand)
      .catch(notifyAndRevert);

  return (
    <SettingsView
      className="container-lg"
      load={load}
      apply={apply}
      clear={clear}
      ContextMenu={OptimizationContextMenu}
      title="Optimization settings"
      applyActionClassName="hidden-to-operators"
      wrapIntoForm={false}
      subtitle={
        <small>
          Press <code>Apply</code> button at the end of the page to set the new
          configuration values.
        </small>
      }
    >
      <ShowOptimizationSettings />
    </SettingsView>
  );
};

const ShowOptimizationSettings = ({
  defaults,
  state,
  onStateUpdate = doesNothing,
}) => {
  const updateSettings = (update) => {
    onStateUpdate(update);
  };

  const updateBypassSettigns = (update) => {
    onStateUpdate({
      bypass: {
        ...state.bypass,
        ...update
      }
    });
  };

  return (
    <div className="body">
      <div className="row">
        <ArrangeInColumns alignToTop={true}>
          <div>
            <BypassSettings
              onChange={updateBypassSettigns}
              defaults={defaults.bypass}
              state={state.bypass}
            />
          </div>
          <div>
            <TCPAndShapingSettings
              onChange={updateSettings}
              defaults={defaults}
              state={state}
            />
          </div>
        </ArrangeInColumns>
      </div>
    </div>
  );
};

export default OptimizationConfig;
