import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import * as d3 from "d3";

import ArrangeInColumns from "common/layouts/ArrangeInColumns";
import SelectInputWithIcon from "common/SelectInputWithIcon";
import ActionsContext from "common/ActionsContext";
import { retrieveRange, calcRangeExtent, getSampleSize } from "common/api";
import { useScreenSize } from "common/hooks";
import RangeSliderInput from "common/RangeSliderInput";
import colors from "common/graphs/colors";
import colorsDark from "common/graphs/colorsDark";
import Loading from "common/Loading";
import {
  PERIOD_TO_HOURS,
  PERIOD_TO_LINES,
  periodScopeChoices,
} from "common/constants";
import Legend from "common/graphs/Legend";
import { parseStatsCpuData, getInRangeItems } from "./api";
import Chart from "./Chart";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const SelectWrapper = styled.div`
  width: 150px;
`;

const SelectsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media screen and (max-width: 700px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const Empty = styled.div`
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "AllianceNo2-SemiBold" !important;
`;

const ChartContainer = styled.div`
  flex: 100% 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  svg.nvd3-svg .nv-axis path.domain {
    stroke-opacity: 0;
  }

  .nv-y.nv-axis .nv-axisMaxMin {
    display: none;
  }

  .nv-y.nv-axis .tick.zero line {
    stroke: transparent !important;
  }

  .nvd3 .nv-groups path.nv-line {
    stroke-width: 1.1;
  }
`;

const RangeContainer = styled.div`
  margin: 20px 0;
`;

const LegendContainer = styled.div`
  width: 100%;
  padding: 25px 55px 0px 55px;
`;
const ChartAndLegendContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-height: 500px;
` 

const _scaleScopeChoices = [
  { value: "Adaptive", label: "Adaptive" },
  { value: "Full", label: "Full" },
];

const _chartScopeChoices = [
  { value: "busy", label: "Busy" },
  { value: "idle", label: "Idle" },
  { value: "system", label: "System" },
  { value: "user", label: "User" },
  { value: "soft", label: "Soft-IRQ" },
];

const SysStatsCpuReport = () => {
  const actions = useContext(ActionsContext);
  const [period, setPeriod] = useState("Day");
  const [scale, setScale] = useState("Full");
  const [chart, setChart] = useState("busy");
  const [cpu, setCpu] = useState("All");
  const [data, setData] = useState({ fields: [], items: [] });
  const [cpuChoices, setCpuChoices] = useState([
    { value: "All", label: "All" },
  ]);
  const [initialRange, setInitialRange] = useState();
  const [filteredItems, setFilteredItems] = useState([]);
  const [newFields, setNewFields] = useState([]);
  const [legendFieldsSet, setLegendFieldsSet] = useState(new Set());
  const [range, setRange] = useState();
  const colorsSet = login.isTheme("light") ? colors : colorsDark;
  const [loading, setLoading] = useState(false);
  const { width: windowWidth } = useScreenSize();

  const doLoad = () => {
    setLoading(true);
    setData({ fields: [], items: [] });
    const hours = PERIOD_TO_HOURS[period];
    retrieveRange(hours)
      .then(({ from, to }) => {
        const chartN = chart === "busy" ? "idle" : chart;
        setInitialRange({ from, to });
        return ifCl
          .run(`show statistics cpu ${chartN} lines ${PERIOD_TO_LINES[period]}`)
          .then((response) => {
            return parseStatsCpuData(response, period, from, to);
          });
      })
      .then((response) => {
        const { items, fields } = response;
        const [, ...cpuNames] = fields;
        if (cpuNames?.length > 0) {
          setCpuChoices(
            ["All", "Average", ...cpuNames].map((name) => {
              return { label: name, value: name };
            })
          );
        } else {
          setLoading(false);
        }
        setData(response);
      })
      .catch((error) => {
        console.warn(
          `Cpu statistics lines retrieval failed: ${
            !error.message ? "unknown reason" : error.message
          }`
        );
        setLoading(false);
      });
  };

  useEffect(() => {
    setRange(undefined);
    doLoad();

    return actions.recv("do-load", doLoad);
  }, [period]);

  const { fields, items } = data;
  const newFieldsValues = fields.map((field, index) => {
    const colorIndex = index % colorsSet.length;
    return {
      name: field,
      label: field,
      color: colorsSet[colorIndex],
    };
  });

  useEffect(() => {
    if (fields.length > 0) {
      const selectedRange = range || initialRange;
      const POINTS_PER_TILE_CHART = windowWidth / (1+ Math.ceil(fields.length/25));
      const sampleSize = getSampleSize(selectedRange, POINTS_PER_TILE_CHART, period);
      const selectedFields =
        cpu === "All" ? fields : fields.filter((item) => item === cpu);
      
      const fieldsSet = new Set(selectedFields);
      setLegendFieldsSet(fieldsSet);
      const newFields = newFieldsValues.filter(({ name }) => fieldsSet.has(name));
      setNewFields(newFields);
      const newItems = getInRangeItems({items, range: selectedRange, fields: newFields, sampleSize, cpu, chart});
      setFilteredItems(newItems);
      setLoading(false);
    }
  }, [range, cpu, chart, fields, items, windowWidth]);

  return (
    <Wrapper>
      <SelectsWrapper>
        <SelectWrapper>
          <SelectInputWithIcon
            title="Scale"
            name="Scale"
            icon="zoom_out_map"
            selected={scale}
            onChange={({ target }) => setScale(target.value)}
            options={_scaleScopeChoices}
          />
        </SelectWrapper>
        <SelectWrapper>
          <SelectInputWithIcon
            title="Chart"
            name="Chart"
            icon="timeline"
            selected={chart}
            onChange={({ target }) => setChart(target.value)}
            options={_chartScopeChoices}
          />
        </SelectWrapper>
        <SelectWrapper>
          <SelectInputWithIcon
            title="Cpu"
            name="Cpu"
            icon="memory"
            selected={cpu}
            onChange={({ target }) => setCpu(target.value)}
            options={cpuChoices}
          />
        </SelectWrapper>
        <SelectWrapper>
          <SelectInputWithIcon
            title="Date Range"
            name="Date Range"
            icon="date_range"
            selected={period}
            onChange={({ target }) => setPeriod(target.value)}
            options={periodScopeChoices}
          />
        </SelectWrapper>
      </SelectsWrapper>
      <ChartAndLegendContainer>
      {!loading ? (
        <LegendContainer>
          <Legend
            fields={newFields}
            value={legendFieldsSet}
            onChange={(filterSet) => {
              setLegendFieldsSet(filterSet);
            }}
          />
        </LegendContainer>
      ) : null}
      <ChartContainer>
        {loading ? (
          <Loading />
        ) : items.length === 0 ? (
          <Empty>{"No Data Available"}</Empty>
        ) : (
          <Chart
            fixedYRange={scale === "Full" ? [0, 100] : [0, null]}
            items={filteredItems}
            fields={newFields.filter(({ name }) => legendFieldsSet.has(name))}
          />
        )}
      </ChartContainer>
      </ChartAndLegendContainer>
      <RangeContainer>
        {initialRange && (
          <RangeSliderInput
            {...initialRange}
            onChange={(value) => setRange(value)}
          />
        )}
      </RangeContainer>
    </Wrapper>
  );
};

export default SysStatsCpuReport;
